<template >
      <div style="    margin: 30px 20px;">
        <div style="width: 100%;min-height:1000px;background: #ffffff">
            <div class="content"
                   v-html="hello">
              </div>
        </div>
    </div>
</template>
<script>
import{ policy } from'../api/authority'
    import 'highlight.js/styles/monokai-sublime.css';
export default {
      name: "policy",
  
    data() {
        return{
 hello:''
        }
     
        
    },
    mounted(){
  this.getlmg();
   
    
    },
    methods:{

    getlmg(){
          policy().then((response)=>{
            console.log(response) 
            this.hello=response.data
          })
        }
    
    }
  
}
</script>
<style>
body { max-height:99999px;min-height:1px; } 
*{

-webkit-text-size-adjust: none;
text-size-adjust: none;
}
   .conter{
    margin: 40px 30px;
    
        }
      /* .title  {
          widows: 171px;
          height: 28px;
          font-size:20px ;
          color: #0A1B33;
          font-weight: 800;
              display: flex;
    justify-content: space-between;
      }
      .title_two{
               font-size:12px ;
          color:#999999 ;

      }
      p{
          text-align: left;
line-height:20px;
          color: #0A1B33 ;
          font-size: 12px;
             margin-bottom: 15px;
      }
      h1{
          font-size:14px ;
          color: #0A1B33;
          font-weight: 600;
          margin-bottom: 10px;
      }
      h2{
          font-size:13px ;
          color: #0A1B33;
          font-weight: 600;
          margin-bottom: 10px;
      }
        h3{
          font-size:12px ;
          color: #0A1B33;
          font-weight: 550;
          margin-bottom: 10px;
      } */
</style>