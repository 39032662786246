// 权限管理API  【菜单、角色、权限】
import request from '@/utils/request'

/**
 * 隐私政策
 * @param {*} data
 * @author 小道仙
 * @date 2020年2月19日
 */
export function policy(data) {
	return request({
		url: '/agreement/read?type=1',
		method: 'get',
		data
	})
}

/**
 * 用户协议
 * @param {*} query
 * @author 小道仙
 * @date 2020年2月19日
 */
export function user() {
	return request({
		url: '/agreement/read?type=0',
		method: 'get',

	})
}

/**
 * 用户协议
 * @param {*} query
 * @author 小道仙
 * @date 2020年2月19日
 */
export function asa() {
	return request({
		url: '/agreement/read?type=4',
		method: 'get',

	})
}
export function purchase() {
	return request({
		url: '/agreement/read?type=5',
		method: 'get',

	})
}

/**
 * SDK
 * @param {*} query
 * @author 小道仙
 * @date 2020年2月19日
 */
export function SDK() {
	return request({
		url: '/agreement/read?type=2',
		method: 'get',

	})
}

/**
 * 推广
 * @param {*} 
 * @author 小道仙
 * @date 2020年2月19日
 */
export function tuiguang(query) {
	return request({
		url: '/channel/query',
		method: 'get',
		params: query

	})
}
